@import 'color';
@import 'constants';
@import 'dbot-banner';
@import 'footer';
@import 'notification-banner';

html,
body {
    height: 100%;
    margin: 0px;
    max-width: 100%;
    overflow-x: hidden;
    background: #111 !important;
    color: white !important;
}

h3 {
    color: white !important;
}

a {
    color: #ef5350 !important;
}

.top-image {
    display: none;
}
#header {
    height: 81px;
    width: 100%;
    background-color: #212121 !important;
    border-top: 2px solid #212121 !important;
    border-bottom: 2px solid #ef5350 !important;
}
#header .wrapper,
#topbar .wrapper {
    max-width: 960px;
    margin: 0 auto;
}
#topbar {
    background-color: #111;
    width: 100%;
    color: #fff;
    line-height: 1;
    font-size: 70%;
    text-align: right;
    padding: 3px 20px;
    display: table;
}
#main {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    height: calc(100% - 220px);
}
.left,
.right {
    width: 45%;
    margin: 20px;
    display: inline-block;
    vertical-align: top;
}
.right img {
    width: 200%;
}
.cta {
    margin: 30px auto;
    text-align: center;
}
.contact {
    text-align: center;
}
ul.bullet {
    margin: 0 !important;
}
.barspinner {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -5rem;
    z-index: 9999;

    div {
        background-color: #ef5350 !important;
    }
}
.center {
    text-align: center;
}
.half-width {
    width: 100%;
    float: left;
}
.clear {
    clear: both;
}
.button span {
    text-transform: inherit !important;
}
.show-on-load {
    display: none;
}
#split-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .puzzle-logo {
        padding-left: 20px;
    }
}
#select_language {
    z-index: 101;
}

.logo-wrapper .logo-parent .logo {
    width: 250px !important;
}

/* Keep this below since css after this
 * will be interfering with small
 * screen sizes
 */
@media only screen and (max-width: 980px) {
    .half-width {
        width: 49%;
    }
    .logo-parent {
        margin-left: 21px;
    }
    .half-width p {
        padding: 0 20px 0 20px;
    }
}
@media screen and (max-width: 769px) {
    #header .binary-logo-text {
        display: inline-block !important;
    }
}
@media only screen and (max-width: 480px) {
    #split-container {
        display: block;
        width: auto;
    }
    .top-image {
        display: block;
    }
    .half-width {
        width: 100%;
    }
    .half-width p,
    .half-width img {
        margin: 1em;
    }
    .bottom-image {
        display: none;
    }
    .left,
    .right {
        width: 100%;
        margin: 20px;
        display: block;
        vertical-align: top;
    }
    .clear {
        position: sticky;
        padding: 1px 0 1px 0;
        bottom: 0;
        background-color: #111;
        width: 100%;
        box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.2);
    }
    p {
        margin: 9px auto !important;
    }
}
