.dbot-banner {
    @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@700&display=swap');
    font-family: 'IBM Plex Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
    align-items: center;
    align-self: center;
    display: flex;
    float: left;
    height: calc(100% - 20px);
    margin: 10px 0;

    &__icon {
        margin-right: 8px;
        margin-left: 32px;
    }
    &__ad {
        color: #fff;
        margin-right: 16px;
        display: flex;
        justify-content: center;

        &-text {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
        }
    }
    &__button {
        font-size: 14px;
        background-color: #ff444f;
        font-weight: bold;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        touch-action: manipulation;
        cursor: pointer;
        white-space: nowrap;
        padding: 0 16px;
        display: inline-flex;
        border: 0;
        height: 32px;
        border-radius: 4px;
        transition: all .2s cubic-bezier(.65,.05,.36,1);
        outline: 0;
        position: relative;
        text-decoration: none;
        text-transform: none!important;

        &:hover {
            background: #eb3e48;
        }

        &:focus {
            outline: none;
        }
    }
    &__separator {
        width: 2px;
        height: 36px;
        background-color: #17212c;
    }
}

@media only screen and (max-width: 520px) {
    .dbot-banner {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .dbot-banner {
        &__separator {
            display: none;
        }
    }
}