$CONTENT_WIDTH: 1000px;
$READABLE_CONTENT_WIDTH: 700px;

$BORDER_RADIUS: 3px;
$PAGE_PADDING: 0.5em;
$HEADER_HEIGHT: 3rem;
$HEADER_HEIGHT_MOBILE: 5rem;
$BOX_SHADOW: 0 0 50px 0 rgba(0, 0, 0, 0.25);

$FONT_BASE: 15px;
$FONT_SCALE: 1.2;
$LINE_HEIGHT: 1.25;

// Use only these font sizes throughout the site
$FONT_SIZE_S: 0.85em;
$FONT_SIZE_XS: $FONT_BASE / $FONT_SCALE / $FONT_SCALE; // .75614rem   =~ 12px

// Brand colors and derivatives
/* stylelint-disable color-no-hex */
$COLOR_ORANGE: #e98024;

// Colors used for secondary purposes. To be used sparingly
$COLOR_RED           : #c03;
$COLOR_YELLOW        : #fef1cf;
$COLOR_GRAY          : #dedede;
$COLOR_DARK_GRAY     : #44484c;
/* stylelint-enable color-no-hex */