$brand-gray: #f2f2f2;
$brand-dark-gray: #dedede;
$brand-blue: #2a3052;
$brand-dark-blue: #15212d;
$brand-orange: #e98024;
$brand-btn: #2e8836;
$brand-red: #ff444f;
$brand-btn-active: #14602b;
$brand-primary-dark: #15212d;
$white: #ffffff;
$black: #000000;
$toolbox-btn-active: #dddddd;
